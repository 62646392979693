import {
  ExportKeyButtonWrapper,
  StyledCopyIcon,
  WalletDetailItemContainer,
  WalletDetailItemNetworkContainer,
  WalletDetailListBodyContainer,
  WalletDetailListContainer,
  WalletDetailListHeaderContainer,
  WidgeWrapper,
} from "./styled";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Box, IconButton, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-brand.svg";
import { useState } from "react";
import { ExportPrivateKeyModal } from "./ExportPrivateKeyModal";
import { copyToClipboard } from "utils";

enum NetworkShortForm {
  ETH,
  SOL,
  TON,
}

const WalletTypeMaps = {
  ETH: "ethAddress",
  SOL: "solAddress",
  TON: "tonAddress",
};

const WalletDetail = () => {
  const { walletAddresses } = useUserStoreV2();

  const [isOpennedModal, setIsOpennedModal] = useState(false);
  const [copiedWalletIndex, setCopiedWalletIndex] = useState<number | null>(null);

  const handleOpenModal = () => {
    setIsOpennedModal(true);
  };

  const handleCloseModal = () => {
    setIsOpennedModal(false);
  };

  return (
    <>
      <WidgeWrapper>
        <WalletDetailListHeaderContainer>
          <Typography variant="pre-title" color="text.secondary">
            WALLET ADDRESS
          </Typography>
          <ExportKeyButtonWrapper>
            <RetroButton variant="white" onClick={handleOpenModal}>
              <Typography variant="button-md">EXPORT KEY</Typography>
            </RetroButton>
          </ExportKeyButtonWrapper>
        </WalletDetailListHeaderContainer>
        <WalletDetailListBodyContainer>
          {walletAddresses &&
            [NetworkShortForm.TON, NetworkShortForm.SOL, NetworkShortForm.ETH].map(
              (network, index) => {
                const walletType = WalletTypeMaps[
                  NetworkShortForm[network] as keyof typeof WalletTypeMaps
                ] as "ethAddress" | "solAddress" | "tonAddress";
                const walletAddress = walletAddresses[walletType] as string;

                return (
                  <WalletDetailItem
                    network={NetworkShortForm[network]}
                    walletAddress={walletAddress}
                    isCopied={copiedWalletIndex === index}
                    setIsCopied={() => setCopiedWalletIndex(index)}
                  />
                );
              },
            )}
        </WalletDetailListBodyContainer>
      </WidgeWrapper>
      <ExportPrivateKeyModal isOpen={isOpennedModal} close={handleCloseModal} />
    </>
  );
};

const WalletDetailItem = ({
  network,
  walletAddress,
  isCopied,
  setIsCopied,
}: {
  network: string;
  walletAddress: string;
  isCopied: boolean;
  setIsCopied: () => void;
}) => {
  const handleCopyWalletAddress = () => {
    setIsCopied();
    copyToClipboard(walletAddress);
  };

  return (
    <>
      <WalletDetailItemContainer>
        <WalletDetailItemNetworkContainer>
          <Typography variant="pre-title" color="text.btn-dark">
            {network}
          </Typography>
        </WalletDetailItemNetworkContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            maxWidth: "100%",
            marginX: "12px",
            overflow: "hidden",
          }}>
          <Typography
            variant="body-md"
            sx={{
              maxWidth: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}>
            {walletAddress.slice(0, walletAddress.length - 4)}
          </Typography>
          <Typography variant="body-md">
            {walletAddress.slice(walletAddress.length - 4, walletAddress.length)}
          </Typography>
        </Box>
        {!isCopied ? (
          <IconButton sx={{ padding: "0px" }} onClick={handleCopyWalletAddress}>
            <CopyIcon width={16} height={16} />
          </IconButton>
        ) : (
          <Typography color="text.system-green">✔</Typography>
        )}
      </WalletDetailItemContainer>
    </>
  );
};
export { WalletDetail };

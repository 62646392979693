import { styled } from "@mui/material";

export type LoadingViewType = "overlay" | "full-screen";

export const LoadingViewContainer = styled("div")<{ $variant: LoadingViewType }>(
  ({ theme, $variant }) => ({
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    zIndex: 99999999,
    width: "100%",
    height: "100%",
    backgroundColor: $variant === "overlay" ? "rgba(0, 0, 0, 0.4)" : theme.palette.surface.silver,
  }),
);

import { ArrowDropUp } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { Brick } from "components/Brick";

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 1,
  maximumSignificantDigits: 4,
  useGrouping: false,
});

const MIN_PERCENTAGE_TO_SHOW = 0.001;

const PercentageBrick = ({ percentage }: { percentage: number }) => {
  const theme = useTheme();

  const averageToFormat =
    percentage >= MIN_PERCENTAGE_TO_SHOW || percentage <= -MIN_PERCENTAGE_TO_SHOW ? percentage : 0;

  return (
    <Brick variant={averageToFormat === 0 ? "dark" : averageToFormat > 0 ? "green" : "red"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}>
        {averageToFormat !== 0 && (
          <ArrowDropUp
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              transform: percentage < 0 ? "rotate(180deg)" : "none",
            }}
          />
        )}
        <Typography variant="body-sm" marginLeft={averageToFormat !== 0 ? theme.spacing(2) : 0}>
          {numberFormatter.format(Math.abs(averageToFormat * 100))}%
        </Typography>
      </Box>
    </Brick>
  );
};

const PricePerformanceBrick = ({ performance }: { performance: number }) => {
  const theme = useTheme();

  const averageToFormat =
    performance >= MIN_PERCENTAGE_TO_SHOW || performance <= -MIN_PERCENTAGE_TO_SHOW
      ? performance
      : 0;

  return (
    <Brick variant={averageToFormat === 0 ? "dark" : averageToFormat > 0 ? "green" : "red"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}>
        {averageToFormat !== 0 && (
          <ArrowDropUp
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              transform: performance < 0 ? "rotate(180deg)" : "none",
            }}
          />
        )}
        <Typography variant="body-sm" marginLeft={averageToFormat !== 0 ? theme.spacing(2) : 0}>
          {`$${numberFormatter.format(Number(performance.toFixed(2)))}`.replace("$-", "-$")}
        </Typography>
      </Box>
    </Brick>
  );
};

export { PercentageBrick, PricePerformanceBrick };

import { RetroButton } from "components/RetroButton";
import {
  PortfolioDetailListContainer,
  PortfolioDetailListHeader,
  PortfolioDetailListItemCabalIcon,
  PortfolioDetailListItemColumnContainer,
  PortfolioDetailListItemContainer,
  PortfolioDetailListTitle,
  TapsContainer,
  TokenNetworkContainer,
} from "./styled";
import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { PercentageBrick, PricePerformanceBrick } from "components/PercentageBrick";
import CoinAvatar from "components/CoinAvatar";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { UserPortfolioAssets, UserPortfolioEarning, UserPortfolioJoinedGroup } from "types";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { LoadingView } from "components/LoadingScreen";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import { ScrollableBox } from "components/ScrollableBox";
import { LoadMoreRow } from "components/LoadMoreRow";
import { useNavigate } from "react-router-dom";

export const PortfolioDetail = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <TapsContainer>
        {["Assets", "Earning", "Groups"].map((tab, index) => {
          return (
            <RetroButton disabled={selectedTab === index} onClick={() => setSelectedTab(index)}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography
                  variant="body-default"
                  color={selectedTab === index ? "text.primary" : "text.brand"}>
                  {tab}
                </Typography>
              </Box>
            </RetroButton>
          );
        })}
      </TapsContainer>
      <PortfolioDetailListContainer>
        {selectedTab === 0 && <AssetsView />}
        {selectedTab === 1 && <EarningView />}
        {selectedTab === 2 && <GroupsView />}
      </PortfolioDetailListContainer>
    </>
  );
};

const AssetsView = () => {
  const [page, setPage] = useState(1);

  const queryResult = useInfiniteQuery({
    queryKey: ["portfolio-assets", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioAssets({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            TOKEN
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            USD VALUE
          </Typography>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <LoadingView isLoading={isLoading} />
      {data && data.pages.length > 0 ? (
        <ScrollableBox>
          {data.pages
            ?.flatMap((page, index) => page.results)
            .map((groupdata, index) => (
              <AssetsListItem key={index} data={groupdata} />
            ))}
          {!hasAllFetched && <LoadMoreRow ref={ref} />}
        </ScrollableBox>
      ) : null}
    </>
  );
};

const EarningView = () => {
  const [page, setPage] = useState(1);

  const queryResult = useInfiniteQuery({
    queryKey: ["portfolio-earnings", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioEarning({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            TOKEN
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            USD VALUE
          </Typography>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <LoadingView isLoading={isLoading} />
      {data && data.pages.length > 0 ? (
        <ScrollableBox>
          {data.pages
            ?.flatMap((page, index) => page.results)
            .map((earningData, index) => (
              <EarningListItem key={index} data={earningData} />
            ))}
          {!hasAllFetched && <LoadMoreRow ref={ref} />}
        </ScrollableBox>
      ) : null}
    </>
  );
};

const GroupsView = () => {
  const [page, setPage] = useState(1);

  const queryResult = useInfiniteQuery({
    queryKey: ["portfolio-groups", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioGroups({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            CABAL NAME
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            USD VALUE
          </Typography>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <LoadingView isLoading={isLoading} />
      {data && data.pages.length > 0 ? (
        <ScrollableBox>
          {data.pages
            ?.flatMap((page, index) => page.results)
            .map((groupdata, index) => (
              <GroupListItem key={index} data={groupdata} />
            ))}
          {!hasAllFetched && <LoadMoreRow ref={ref} />}
        </ScrollableBox>
      ) : null}
    </>
  );
};

const AssetsListItem = ({ data }: { data: UserPortfolioAssets }) => {
  const navigate = useNavigate();

  const navigateToTrade = () => {
    navigate({
      pathname: `/token/${data.tokenAddress}`,
      search: `groupId=${data.tgGroupId}`,
    });
  };

  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "32px",
          }}>
          <CoinAvatar iconUri={data.imageUrl} chain={data.chain} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "16px",
          }}>
          {/* Balance */}
          <Typography variant="body-default" color="text.primary">
            ${data.balance}
          </Typography>
          {/* Token Type */}
          <TokenNetworkContainer>
            <Typography variant="pre-title-2" color="text.btn-dark">
              {data.symbol}
            </Typography>
          </TokenNetworkContainer>
          {/* <Typography variant="subtitle" color="text.primary">Cabal Name</Typography> */}
        </Box>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography variant="body-default" color="text.primary">
            ${data.priceUsd}
          </Typography>
          <PricePerformanceBrick performance={data.performance ?? 0} />
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton variant="primary" onClick={navigateToTrade}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography variant="button-md" color="text.brand">
                TRADE
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};

const EarningListItem = ({ data }: { data: UserPortfolioEarning }) => {
  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "32px",
          }}>
          <CoinAvatar iconUri={data.imageUrl} chain={data.chain} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "16px",
          }}>
          {/* Balance */}
          <Typography variant="body-default" color="text.primary">
            ${data.balance}
          </Typography>
          {/* Token Type */}
          <TokenNetworkContainer>
            <Typography variant="pre-title-2" color="text.btn-dark">
              {data.symbol}
            </Typography>
          </TokenNetworkContainer>
          {/* <Typography variant="subtitle" color="text.primary">Cabal Name</Typography> */}
        </Box>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography variant="body-default" color="text.primary">
            ${data.balance}
          </Typography>
          <PercentageBrick percentage={data.performance} />
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton variant="primary" onClick={() => console.log(`balance: ${data.balance}`)}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography variant="button-md" color="text.brand">
                CLAIM
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};

const GroupListItem = ({ data }: { data: UserPortfolioJoinedGroup }) => {
  const navigate = useNavigatePreserveQuery();
  // const {
  //   ref,
  //   inView,
  //   hasAllFetched,
  // } = useInViewInfiniteQuery(groupJettonDetail);

  // const { data: jettonNativePrice, isLoadingJettonPrice } = useQuery({
  //   queryKey: ["jetton-price"],
  //   queryFn: () => axiosService.getNativePrice(data._id),
  //   enabled: !!data,
  // });
  const navigateToGroup = () => {
    navigate(`/cabal/${data.tgGroupId}`);
  };

  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "32px",
          }}>
          <PortfolioDetailListItemCabalIcon src={data.imageUri} alt="cabal-image" />
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            padding: "0 12px",
          }}>
          {data.name}
        </Box>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}>
          <Typography variant="body-default" color="text.primary">
            {data && data.priceUsd ? `$${data.priceUsd.toFixed(2)}` : "--"}
          </Typography>
          <PercentageBrick percentage={data.performance ? data.performance : 0} />
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton variant="white" onClick={navigateToGroup}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography variant="button-md" color="text.btn-dark">
                VIEW
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
      {/* <LoadingView isLoading={isLoading}/> */}
    </PortfolioDetailListItemContainer>
  );
};

import theme from "theme";
import { Container } from "components/container";
import { BondingCurveChartWrapper, BondingCurveTooltipTrigger, JettonImage } from "./styled";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { useNavigate, useParams } from "react-router-dom";
import { RetroButton } from "components/RetroButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { LoadingView } from "components/LoadingView";
import { EmptyView } from "components/EmptyView";
import { abbreviateNumber, LANGUAGES } from "utils";
import { ReactComponent as HelpIcon } from "assets/icons/warning/circle_help.svg";
import { PercentageBrick } from "components/PercentageBrick";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { useChart } from "hooks/useChart";
import { JettonChart } from "components/Chart/JettonChart";
import {
  format,
  startOfHour,
  startOfDay,
  startOfMinute,
  startOfWeek,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMinuteOfInterval,
  eachHourOfInterval,
} from "date-fns";
import { BondingCurveType, JettonPrice } from "types";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { MouseEventParams, Time } from "lightweight-charts";
import { MIN_ENTRY_AMOUNT, ROUTES } from "consts";
import { CabalTradeDrawer } from "components/CabalTradeDrawer";

import { useWsPriceData } from "./hooks";
import { Brick } from "components/Brick";

import { useOnClickOutside } from "usehooks-ts";
import useTransactionStore from "store/transaction-store/useTransationStore";
import FullPageWrapper from "components/FullPageWrapper";
import useToastStore from "store/toast-store/useToastStore";
import { PreviewChart } from "components/Chart/PreviewChart";
import casualData from "components/CreateCabal/data/casual.json";
import standardData from "components/CreateCabal/data/standard.json";
import exclusiveData from "components/CreateCabal/data/exclusive.json";
import _groupBy from "lodash/groupBy";

import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loader-cabal_town.json";
import { ContentCard, InnerContentBox } from "components/ContentCard";
import { ChartTooltipContainer } from "components/Chart/ChartTooltip";

import tradeImage from "../../assets/icons/trade.png";
// https://api.cabal.town/v1/jettons/EQBkFnbZ23QyXS7KyMrQz8n0PLQDgqMv16TDoDXPDCYdiE54/price?sortBy=createdAt:desc&page=1&limit=10000&selects=price%20updatedAt&interval=hour

const ChartTooltip = ({
  x,
  y,
  data,
  position,
}: {
  x: number;
  y: number;
  data: { time: number; value: number };
  position: "top" | "bottom";
}) => {
  return (
    <ChartTooltipContainer
      style={{
        top: y,
        left: x,
        transform: `translate(-50%, calc(${position === "top" ? "24px" : "-100% - 24px"}))`,
      }}>
      <Box>
        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>${data.value}</Box>
        <Box>{format(new Date(data.time), "dd/MM/yyyy")}</Box>
      </Box>
    </ChartTooltipContainer>
  );
};

const groupingFunction = {
  minute: startOfMinute,
  hour: startOfHour,
  day: startOfDay,
  week: startOfWeek,
};

const intervalFunction = {
  minute: eachMinuteOfInterval,
  hour: eachHourOfInterval,
  day: eachDayOfInterval,
  week: eachWeekOfInterval,
};

const groupAndFillJettonPrice = ({
  data,
  interval,
  withoutFirstData,
}: {
  data: JettonPrice[];
  interval: "minute" | "hour" | "day" | "week";
  withoutFirstData: boolean;
}) => {
  const groupedData = _groupBy(data, (d) => {
    return groupingFunction[interval](new Date(d.updatedAt)).toISOString();
  });
  const wholeInterval = intervalFunction[interval]({
    start: new Date(data[0].updatedAt),
    end: new Date(data[data.length - 1].updatedAt),
  });

  const result: JettonPrice[] = [];

  wholeInterval.forEach((d) => {
    const key = startOfMinute(d).toISOString();
    if (groupedData[key]) {
      result.push(Object.values(groupedData[key])[0]);
    } else {
      result.push({
        updatedAt: startOfMinute(d).toISOString(),
        price: result[result.length - 1].price,
      });
    }
  });
  if (withoutFirstData) {
    result.shift();
  }
  return result;
};

const bondingCurveDescriptions: Record<BondingCurveType, string> = {
  casual: "Perfect for large groups, offering steady prices to encourage broad participation.",
  standard: "Ideal for medium-sized groups, striking a balance in price changes.",
  exclusive: "Made for tight-knit, active communities with dynamic token pricing.",
};

const initialData = {
  casual: casualData,
  standard: standardData,
  exclusive: exclusiveData,
};

const BondingCurveTooltip = ({ type }: { type: BondingCurveType }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.surface.silver,
        borderRadius: theme.spacing(3),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.border.dark}`,
        minWidth: "220px",
      }}>
      <Box>
        <Typography variant="subtitle" color={"text.primary"}>
          {type} Pricing
        </Typography>
        <Box marginTop={1}>
          <Typography variant="body-sm" color={"text.secondary"}>
            {bondingCurveDescriptions[type]}
          </Typography>
        </Box>
        <Box marginTop={1} display={"flex"} justifyContent={"center"}>
          <BondingCurveChartWrapper>
            <PreviewChart data={initialData[type]} />
          </BondingCurveChartWrapper>
        </Box>
      </Box>
    </Box>
  );
};

const transformPriceData = (data: JettonPrice) => {
  return {
    time: new Date(data.updatedAt).getTime(),
    value: data.price,
  };
};

const CabalDetailsRenderer = ({ id }: { id?: string }) => {
  const [timeInterval, setTimeInterval] = useState<"minute" | "hour" | "day" | "week">("hour");

  const [shouldTradeDrawerOpen, setShouldTradeDrawerOpen] = useState(false);

  const [priceDataConsolidated, setPriceDataConsolidated] = useState<JettonPrice[]>([]);

  const [isBondingCurveTooltipOpen, setIsBondingCurveTooltipOpen] = useState(false);

  const [isJoiningGroup, setIsJoiningGroup] = useState(false);

  const bondingCurveTooltipRef = useRef<HTMLDivElement>(null);

  const { tgUserName } = useUserStoreV2();

  const { showToast } = useToastStore();

  useOnClickOutside(bondingCurveTooltipRef, () => setIsBondingCurveTooltipOpen(false));

  const [point, setPoint] = useState<{
    x: number;
    y: number;
    position: "top" | "bottom";
    data: {
      time: number;
      value: number;
    };
  } | null>(null);

  const { walletAddresses } = useUserStoreV2();
  const { tonAddress } = walletAddresses || {};

  const { data, isLoading } = useQuery({
    queryKey: ["cabal-details", id],
    queryFn: () => axiosService.getCabalDetails(id!),
    enabled: !!id,
  });

  const { data: jettonPriceRatio } = useQuery({
    queryKey: ["jetton-price", data?.masterAddress],
    queryFn: () =>
      axiosService.getNativePrice({ tokenAddress: data?.masterAddress!, chain: "ton" }),
    enabled: !!data?.masterAddress,
    refetchInterval: 10 * 1000,
  });

  const { data: priceData, isLoading: isPriceDataLoading } = useQuery({
    queryKey: ["jetton-price", { masterAddress: data?.masterAddress, timeInterval }],
    queryFn: () =>
      axiosService.getJettonPriceV1({
        masterAddress: data?.masterAddress!,
        interval: timeInterval,
      }),
    enabled: !!data?.masterAddress,
  });

  useEffect(() => {
    if (priceData?.results && priceData.results.length > 0) {
      setPriceDataConsolidated(
        groupAndFillJettonPrice({
          data: priceData.results,
          interval: timeInterval,
          withoutFirstData: true,
        }),
      );
    }
  }, [priceData, timeInterval, setPriceDataConsolidated]);

  const minimumEntryInUsd =
    jettonPriceRatio && "jettonBuyRatio" in jettonPriceRatio
      ? jettonPriceRatio.jettonBuyRatio * MIN_ENTRY_AMOUNT
      : undefined;

  const {
    chartRef,
    seriesRef,
    chartContainerRef,
    setIsMounted,
    isMounted,
    subscribeCrosshairMove,
    unsubscribeCrosshairMove,
  } = useChart();

  const { data: balanceData } = useQuery({
    queryKey: ["jetton-balance", { masterAddress: data?.masterAddress, walletAddress: tonAddress }],
    queryFn: () =>
      axiosService.getJettonBalance({
        masterAddress: data?.masterAddress!,
        walletAddress: tonAddress!,
      }),
    enabled: !!data?.masterAddress && !!tonAddress,
  });

  const { setTransaction, transactionStore } = useTransactionStore();

  const handleOnCrosshairMove = useCallback(
    (params: MouseEventParams<Time>) => {
      if (!params.point) {
        setPoint(null);
        return;
      }
      const series = seriesRef.current["jetton-price"]["area"];
      const data = params.seriesData.get(series) as {
        time: number;
        value: number;
      };

      if (!data || !data.value) {
        setPoint(null);
        return;
      }

      const y = series.priceToCoordinate(data.value);
      const position = y > (chartContainerRef.current?.clientHeight ?? 0) / 2 ? "bottom" : "top";
      setPoint({ x: params.point.x, y, position, data });
    },
    [seriesRef, chartContainerRef],
  );

  const { mutateAsync: joinGroupAsync } = useMutation({
    mutationFn: () =>
      axiosService.joinGroupV1({
        tokenAddress: data?.masterAddress!,
        walletAddress: tonAddress!,
        tgUserName: tgUserName!,
      }),
  });

  const handleJoinGroup = async () => {
    setIsJoiningGroup(true);
    try {
      const res = await joinGroupAsync();
      if (res.status === 200) {
        showToast({ variant: "success", message: res.message, duration: 3000 });
      }
      if (res.status >= 400) {
        showToast({ variant: "error", message: res.message, duration: 3000 });
      }
    } catch (error) {
      showToast({ variant: "error", message: "Something went wrong", duration: 3000 });
    } finally {
      setIsJoiningGroup(false);
    }
  };

  useEffect(() => {
    if (isMounted) {
      subscribeCrosshairMove(handleOnCrosshairMove);
    }

    return () => {
      unsubscribeCrosshairMove(handleOnCrosshairMove);
    };
  }, [isMounted, subscribeCrosshairMove, unsubscribeCrosshairMove, handleOnCrosshairMove]);

  const handleOnPriceData = useCallback(
    (priceData: JettonPrice) => {
      setPriceDataConsolidated((prev) => [
        ...prev,
        ...(prev.length > 0
          ? groupAndFillJettonPrice({
              data: [prev[prev.length - 1], priceData],
              interval: timeInterval,
              withoutFirstData: true,
            })
          : [priceData]),
      ]);
    },
    [setPriceDataConsolidated, timeInterval],
  );

  useEffect(() => {
    if (priceDataConsolidated.length > 0) {
      chartRef.current?.timeScale().fitContent();
    }
  }, [priceDataConsolidated, chartRef]);

  useWsPriceData({
    masterAddress: data?.masterAddress!,
    onPriceData: handleOnPriceData,
  });

  if (isLoading) {
    return <LoadingView />;
  }

  if (!data) {
    return <EmptyView />;
  }

  const { memberPercentage } = {
    memberPercentage: 10,
  };

  const {
    day1Performance,
    day7Performance,
    day14Performance,
    totalWins,
    totalCalls,
    membersCount,
    imageUri,
    name,
    language,
    bondingCurveType,
  } = data;

  return (
    <>
      <RetroCard>
        <Box
          sx={{
            padding: theme.spacing(2.5),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}>
          <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
            <JettonImage src={imageUri} alt={name} />
            <Box>
              <Typography variant="title-h3">{name}</Typography>
              <Box display={"flex"}>
                <Box sx={{ display: "flex", alignItems: "center", paddingRight: theme.spacing(1) }}>
                  <Typography
                    variant="pre-title"
                    sx={{ textTransform: "uppercase", lineHeight: 1 }}>
                    {LANGUAGES[language as keyof typeof LANGUAGES]}
                  </Typography>
                </Box>
                <Box paddingRight={theme.spacing(0.5)}> · </Box>
                <BondingCurveTooltipTrigger
                  ref={bondingCurveTooltipRef}
                  isOpen={isBondingCurveTooltipOpen}
                  onClick={() => setIsBondingCurveTooltipOpen(!isBondingCurveTooltipOpen)}>
                  <Typography
                    variant="pre-title"
                    sx={{
                      cursor: "pointer",
                      textTransform: "uppercase",
                      lineHeight: 1,
                      marginRight: theme.spacing(0.5),
                    }}>
                    {bondingCurveType}
                  </Typography>

                  <HelpIcon />

                  {isBondingCurveTooltipOpen && (
                    <Box
                      position={"absolute"}
                      top={24}
                      left={"50%"}
                      sx={{
                        transform: "translateX(-50%)",
                      }}>
                      <BondingCurveTooltip type={bondingCurveType} />
                    </Box>
                  )}
                </BondingCurveTooltipTrigger>
              </Box>
            </Box>
          </Box>

          <ContentCard title="minimum amount to enter">
            <InnerContentBox
              marginTop={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: theme.spacing(1),
              }}>
              <Typography variant="title-h2">
                {minimumEntryInUsd ? `$${minimumEntryInUsd.toFixed(2)}` : "Loading..."}
              </Typography>
              <Box
                paddingY={0.5}
                paddingX={1}
                sx={{
                  backgroundColor: theme.palette.surface.silver,
                  border: `1px solid ${theme.palette.border.dark}`,
                  borderRadius: theme.spacing(1),
                }}>
                <Typography variant="body-md" color={theme.palette.text.secondary}>
                  {abbreviateNumber(MIN_ENTRY_AMOUNT)} tokens
                </Typography>
              </Box>
            </InnerContentBox>
          </ContentCard>

          <Box sx={{ display: "flex", gap: theme.spacing(1.5) }}>
            <ContentCard title="Member" sx={{ flex: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                <Typography variant="title-h2" sx={{ minWidth: theme.spacing(2) }}>
                  {abbreviateNumber(membersCount)}
                </Typography>
                {/* <PercentageBrick percentage={memberPercentage} /> */}
              </Box>
            </ContentCard>
            <ContentCard title="Win RATE (7D)" sx={{ flex: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                {totalWins === 0 && totalCalls === 0 ? (
                  <Typography variant="title-h2">TBC</Typography>
                ) : (
                  <Typography variant="title-h2">
                    {totalWins} / {totalCalls}
                  </Typography>
                )}
              </Box>
            </ContentCard>
          </Box>
          <ContentCard title="Performance">
            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              {[
                {
                  title: "24H",
                  value: day1Performance,
                },
                {
                  title: "7D",
                  value: day7Performance,
                },
                {
                  title: "14D",
                  value: day14Performance,
                },
              ]?.map((item, index) => (
                <InnerContentBox
                  marginTop={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: theme.spacing(1),
                    flex: 1,
                  }}>
                  <Typography variant="title-h2">{item.title}</Typography>
                  {item.value === 0 || item.value.toFixed(2) === "0.00" ? (
                    <Brick variant="dark">
                      <Typography variant="body-sm">TBC</Typography>
                    </Brick>
                  ) : (
                    <PercentageBrick percentage={item.value} />
                  )}
                </InnerContentBox>
              ))}
            </Box>
          </ContentCard>
        </Box>
      </RetroCard>
      <RetroCard>
        <Box
          sx={{
            padding: theme.spacing(2.5),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}>
          <Typography variant="title-h3">Chart</Typography>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "4/3",
              borderRadius: theme.spacing(3),
              overflow: "hidden",
            }}>
            {!isPriceDataLoading && priceDataConsolidated.length > 0 ? (
              <JettonChart
                chartRef={chartRef}
                seriesRef={seriesRef}
                chartContainerRef={chartContainerRef}
                setIsMounted={setIsMounted}
                data={priceDataConsolidated.map(transformPriceData) ?? []}
                tooltip={
                  point ? (
                    <ChartTooltip
                      x={point.x}
                      y={point.y}
                      position={point.position}
                      data={point.data}
                    />
                  ) : null
                }
              />
            ) : isPriceDataLoading ? (
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                <Box
                  padding={theme.spacing(2)}
                  width={"100%"}
                  height={"100%"}
                  maxWidth={250}
                  maxHeight={250}>
                  <Lottie animationData={loadingAnimation} />
                </Box>
              </Box>
            ) : (
              <Box height={"100%"}>
                <Box
                  padding={theme.spacing(2)}
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}>
                  <img src={tradeImage} alt="trade" />
                  <Box
                    sx={{
                      textAlign: "center",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginTop: "24px",
                    }}>
                    <Typography variant="title-h2">No data yet</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              width: "100%",
              justifyContent: "center",
            }}>
            {[
              { label: "1m", value: "minute" },
              { label: "1h", value: "hour" },
              { label: "1d", value: "day" },
              { label: "1w", value: "week" },
            ].map((item) => (
              <RetroButton
                size="sm"
                onClick={() => setTimeInterval(item.value as "minute" | "hour" | "day" | "week")}
                disabled={timeInterval === item.value}>
                <Box paddingX={2}>
                  <Typography variant="body-default">{item.label}</Typography>
                </Box>
              </RetroButton>
            ))}
          </Box>
        </Box>
      </RetroCard>
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            padding: theme.spacing(2.5),
          }}>
          <Typography variant="title-h3">
            your balance
            <HelpIcon />
          </Typography>

          <ContentCard title="USD Value" sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
              <Typography variant="title-h2">${(balanceData?.usdValue ?? 0).toFixed(2)}</Typography>
              <PercentageBrick percentage={balanceData?.performance ?? 0} />
            </Box>
          </ContentCard>
          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Quantity" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {abbreviateNumber(balanceData?.quantity ?? 0)}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="P&L" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {(balanceData?.performance ?? 0).toFixed(2)}
                  </span>
                  <span style={{ marginLeft: "4px" }}>USD</span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>
        </Box>
      </RetroCard>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 3,
          paddingBottom: theme.spacing(1),
        }}>
        <RetroCard narrowOuter size="sm">
          <Box
            sx={{
              padding: theme.spacing(1, 1.5),
              height: "80px",
              display: "flex",
              gap: theme.spacing(1),
            }}>
            {!!balanceData?.quantity && +balanceData.quantity >= MIN_ENTRY_AMOUNT && (
              <RetroButton onClick={handleJoinGroup} variant="white" disabled={isJoiningGroup}>
                <Typography variant="title-h3">Join</Typography>
              </RetroButton>
            )}
            <RetroButton
              onClick={() => setShouldTradeDrawerOpen(true)}
              disabled={!!transactionStore.tokenAddress || !!transactionStore.walletAddress}>
              <Typography variant="title-h3">Trade</Typography>
            </RetroButton>
          </Box>
        </RetroCard>
      </Box>
      {data?.masterAddress && minimumEntryInUsd && (
        <CabalTradeDrawer
          open={shouldTradeDrawerOpen}
          onClose={() => setShouldTradeDrawerOpen(false)}
          balance={balanceData?.balance ?? 0}
          tokenQuantity={balanceData?.quantity ?? 0}
          masterAddress={data.masterAddress}
          buyMinimum={minimumEntryInUsd}
          tgGroupId={data.tgGroupId}
          jettonIcon={imageUri}
          onConfirm={() => {
            setTransaction({
              tokenAddress: data.masterAddress,
              walletAddress: tonAddress,
            });
            setShouldTradeDrawerOpen(false);
          }}
        />
      )}
    </>
  );
};

const CabalIdPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <FullPageWrapper>
      <Container>
        <Box paddingY={theme.spacing(2.5)}>
          <Button
            sx={{ padding: "0", cursor: "pointer" }}
            onClick={() => navigate(ROUTES.explorer)}>
            <BackArrow />
            <Typography variant="button-md" color="text.secondary">
              Explore
            </Typography>
          </Button>
        </Box>
      </Container>
      <Container sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
        {id && <CabalDetailsRenderer id={id} />}
      </Container>
    </FullPageWrapper>
  );
};

export { CabalIdPage };
